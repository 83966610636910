.RebootModalText {
  margin-top: 20px;
  font-size: 20px;
  text-align: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  position: sticky;
}
