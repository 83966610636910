.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.login-header {
  display: flex;
  justify-content: center;
  margin-bottom: 44px;
}
.login-header img {
  max-width: 30vw;
}

ion-item {
  margin-bottom: 12px;
}

ion-button {
  margin-top: 24px;
  margin-bottom: 12px;
}
