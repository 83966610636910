@import './fonts.css';

table {
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
}

table,
th,
td {
  padding: 10px;
  /* padding = hauteur de ligne  */
  text-align: left;
}

thead {
  position: sticky;
  top: 0;
  background-color: var(--ion-color-light-tint);
  text-transform: uppercase;
}

.table_container {
  max-height: 70vh;
  min-height: 40vh;
  overflow: auto;
  margin: 10px;
  border: 1px solid var(--ion-color-light-tint);
  border-radius: 5px;
}
.big{
  min-height: 10vh;
}
.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.money,
.weight,
.temperature {
  font-weight: bold;
  font-size: 1.1em;
  color: var(--ion-color-success);
}

.bg_danger {
  background-color: rgb(255, 0, 0);
  color: var(--ion-color-danger-contrast);
}

tr:nth-child(even) .bg_danger {
  /* background-color: var(--ion-color-danger-tint); */
  background-color: rgba(255, 0, 0, 0.832);
}
.danger {
  color: var(--ion-color-danger);
}

.success {
  color: var(--ion-color-success);
}
.bg_warning {
  background-color: rgba(255, 234, 0, 0.978);
  color: var(--ion-color-warning-contrast);
}

.image_clignote {
  animation-duration: 0.8s;
  animation-name: clignoter;
  animation-iteration-count: infinite;
  transition: none;
  background-color: rgba(148, 21, 120, 0.978);
  color: rgb(253, 244, 244);
  animation-duration: 2s;
}
@keyframes clignoter {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

tr:nth-child(even) .bg_warning {
  background-color: yellow;
  color: var(--ion-color-warning-contrast);
}

tr:nth-child(even) {
  background-color: var(--ion-color-light-tint);
}

ion-title {
  text-transform: uppercase;
}

input {
  padding: 0.5rem;
  border: none;
  /* box-shadow: 0 0 0 1px var(--ion-color-light-tint); */
  border-radius: 5px;
  border: 0px solid var(--ion-color-light-tint);
  width: 100%;
}

.tab_container {
  padding-right: 1rem;
  gap: 70px;
  height: 80vh;
  
  overflow-y: auto;
}

table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

th {
  position: sticky;
}
@media (max-width: 800px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: 5fr 5fr 1fr 1fr;
    height: 90vh;
  }
}

input {
  background-color: var(--ion-color-light-tint);
}

button {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
}
