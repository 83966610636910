.tableWrapper {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.tablemenuContainer {
  gap: 70px;
  height: 80vh;
  overflow-y: auto;
  /* background-color: blue; */
}

.alertSwitcher {
  display: flex;
  align-items: center;
}
.tablemenuContainer table {
  /* background-color: rgb(66, 99, 66); */
  width: 100%;
  border-collapse: collapse;
}

/* .tablemenuContainer tbody {
  background-color: blueviolet;
} */
.tablemenuContainer th {
  position: sticky;
}
