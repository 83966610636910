/* Font faces */
/* @font-face {
  font-family: 'Digital Serial';
  src: url('./assets/fonts/digital-serial/Digital-Serial-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: 'Digital Serial';
  src: url('./assets/fonts/digital-serial/Digital-Serial-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Digital Serial';
  src: url('./assets/fonts/digital-serial/Digital-Serial-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Digital Serial';
  src: url('./assets/fonts/digital-serial/Digital-Serial-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

:root {
  font-size: 13px;
}

* {
  font-family: 'Digital Serial', sans-serif;
}
